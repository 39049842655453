import {
	HttpErrorResponse,
	HttpHeaders,
	HttpParams,
} from "@angular/common/http";
import { computed, Injectable, signal } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, of } from "rxjs";
@Injectable({
	providedIn: "root",
})
export class UtilsService {
	private idClientObservable$: BehaviorSubject<string> =
		new BehaviorSubject<string>(null);
	private idAccountObservable: BehaviorSubject<string> =
		new BehaviorSubject<string>("1");
	private emailClientObservable: BehaviorSubject<string> =
		new BehaviorSubject<string>("");
	private documentTypeClientObservable: BehaviorSubject<string> =
		new BehaviorSubject<string>("");
	private documentTypeMetamap: BehaviorSubject<string> =
		new BehaviorSubject<string>("");
	private documentNumberClientObservable: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	private productNumberObservable: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	private cryptoNumberObservable: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	private declarationClientObservable: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	private cryptoActivitieObservable: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	private agreementsTermsObservable: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private statusRequestObservable: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	private countryClientObservable: BehaviorSubject<string> =
		new BehaviorSubject<string>("");
	private countryResidentClientObservable: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	private typeSendMethod: number = 1;

	private hasExistUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);
	private hasCryptoUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);
	private isUpdateClient: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private clientId = signal('');
	clientID = computed(() => this.clientId())
	objValidateUserOnline: {
		$type: string;
		TokenCrypto: string;
		UserName: string;
		DocumentNumber: string;
		DocumentType: { $type: string; DocumentTypeId: number };
	};


	get getIdClientObservable() {
		return this.idClientObservable$.asObservable();
	}

	set setIdClientObservable(data: string) {
		this.clientId.set(data)
		this.idClientObservable$.next(data);
	}

	get getSharedTypeAccountObservable() {
		return this.idAccountObservable.asObservable();
	}

	set setSharedTypeAccountObservableData(data: string) {
		this.idAccountObservable.next(data);
	}

	get sharedEmailObservable() {
		return this.emailClientObservable.asObservable();
	}

	get getEmailObservableValue() {
		return this.emailClientObservable.value;
	}

	set sharedEmailObservableData(data: string) {
		this.emailClientObservable.next(data);
	}

	get sharedDocumentObservable() {
		return this.documentTypeClientObservable.asObservable();
	}

	set setDocumentTypeObservable(data: string) {
		this.documentTypeMetamap.next(data);
	}

	get getDocumentTypeObservable() {
		return this.documentTypeMetamap.asObservable();
	}

	set sharedDocumentObservableData(data: string) {
		this.documentTypeClientObservable.next(data);
	}

	get sharedDocumentNumberObservable() {
		return this.documentNumberClientObservable.asObservable();
	}

	set sharedDocumentNumberObservableData(data: number) {
		this.documentNumberClientObservable.next(data);
	}

	get sharedProductNumberObservable(): Observable<number> {
		return this.productNumberObservable.asObservable();
	}

	set sharedProductNumberObservableData(data: number) {
		this.productNumberObservable.next(data);
	}

	get sharedCryptoNumberObservable() {
		return this.cryptoNumberObservable.asObservable();
	}

	set sharedCryptoNumberObservableData(data: number) {
		this.cryptoNumberObservable.next(data);
	}

	get sharedDeclarationObservable() {
		return this.declarationClientObservable.asObservable();
	}

	set sharedDeclarationObservableData(data: number) {
		this.declarationClientObservable.next(data);
	}

	get activityCryptoObservable() {
		return this.cryptoActivitieObservable.asObservable();
	}

	set activityCryptoObservableData(data: number) {
		this.cryptoActivitieObservable.next(data);
	}

	get sharedAgreementsObservable() {
		return this.agreementsTermsObservable.asObservable();
	}

	set sharedAgreementsObservableData(data: boolean) {
		this.agreementsTermsObservable.next(data);
	}

	get statusObservable() {
		return this.statusRequestObservable.asObservable();
	}

	set statusObservableData(data: number) {
		this.statusRequestObservable.next(data);
	}

	get sharedCountryClientObservable() {
		return this.countryClientObservable.asObservable();
	}

	set sharedCountryClientObservableData(data: string) {
		this.countryClientObservable.next(data);
	}

	get sharedCountryResidentClientObservable() {
		return this.countryResidentClientObservable.asObservable();
	}

	set sharedCountryResidentClientObservableData(data: number) {
		this.countryResidentClientObservable.next(data);
	}

	set setTypeSendMethodCard(typeSend: number) {
		this.typeSendMethod = typeSend;
	}

	get getTypeSendMethodCard(): number {
		return this.typeSendMethod;
	}

	set setHasExistUser(data: boolean) {
		this.hasExistUser.next(data);
	}

	get getHasExistUser() {
		return this.hasExistUser.value;
	}

	set setHasCryptoUser(data: boolean) {
		this.hasCryptoUser.next(data);
	}

	get getHasCryptoUser() {
		return this.hasCryptoUser.value;
	}

	get getIsUpdateClient(): boolean {
		return this.isUpdateClient.value;
	}

	set setIsUpdateClient(data: boolean) {
		this.isUpdateClient.next(data);
	}

	public buildValidateUser(document: string, documentTypeId: number): void {

		this.objValidateUserOnline = {
			$type:
				"Tailored.ICBanking.UIProcess.MethodParameters.Framework.CryptoCustom.ValidateUserDataIn, Tailored.ICBanking.UIProcess",
			TokenCrypto:
				"XXXXXXXSXSXSXSXSXSXSXSXSXSXXSPLPLXPLPSLXPSLXPSLSPLPLSPLSPLPLSX",
			UserName: " ",
			DocumentNumber: document,
			DocumentType: {
				$type:
					"Infocorp.UIProcess.Entities.Framework.Common.DocumentType, Infocorp.UIProcess.Entities",
				DocumentTypeId: documentTypeId,
			},
		};
	}

	get getObjectValidateUserOnline() {
		return this.objValidateUserOnline;
	}

	/**
	 * Handles response
	 * Método para manejar las respuestas de api.
	 * @param res
	 * @returns
	 */
	handleResponse(res: any) {
		switch (res.status) {
			case 200:
			case 201:
			case 204:
				return res.body || "unhandled response";
			default:
				return this.handleError(res);
		}
	}

	/**
	 * Handles error
	 * Método para controlar los errores desde api.
	 * @param error
	 * @returns
	 */
	handleError(error: HttpErrorResponse) {
		return of(error);
	}

	/**
	 * Sets query params
	 * Método para crear los parametros por url.
	 * @param pageIndex
	 * @param pageSize
	 * @returns
	 */
	setQueryParams(pageIndex: number, pageSize: number) {
		const params = new HttpParams()
			.set("offset", pageIndex)
			.set("limit", pageSize);
		return params;
	}
	
	setParams(idCountry: number, idProvince?: number): HttpParams {
		return new HttpParams()
			.set("countryId", idCountry)
			.set("provinceId", idProvince)
			.set("pageSize", "5000");
	}
}
