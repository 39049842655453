import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, of, from } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { EncryptionService } from "../services/EncryptionService/encryption-service.service";
import { environment } from "@environments/environment";

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {
  private ENV = environment.api;

  constructor(private encryptionService: EncryptionService) {}

  /*
   * Interceptamos todas la solicitudes HTTP
   * dependiendo el tipo de peticion lo enviamos a una funcion especifica
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const urlDocuments = `${this.ENV}/Document/upload-document`;
    const [baseUrl] = req.url.split("?");

    if (baseUrl === urlDocuments) {
      return this.handleRegularRequest(req, next);
    } else {
      if (req.method === "POST" || req.method === "PUT") {
        return this.handleEncryptedRequest(req, next);
      } else {
        return this.handleRegularRequest(req, next);
      }
    }
  }

  /*
   * Aqui encriptamos el cuerpo de la peticion
   * Enviamos la peticion encriptada hacia el handleEncryptedResponse
   */
  private handleEncryptedRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.encryptionService.encryptMessage(req.body)).pipe(
      switchMap((encryptedBody) => {
        const clonedReq = req.clone({ body: { data: encryptedBody } });
        return next.handle(clonedReq).pipe(
          switchMap((event: HttpEvent<any>) =>
            this.handleEncryptedResponse(event)
          ),
          catchError((error) => {
            //console.error("Error handling encrypted request:", error);
            return throwError(() => error);
          })
        );
      }),
      catchError((error) => {
        return this.handleErrorResponse(error);
      })
    );
  }

  /*
   * Verificamos si el cuerpo contiene datos encriptados.
   * Si contiene algo encriptado llama al decryptResponseBody
   */
  private handleEncryptedResponse(
    event: HttpEvent<any>
  ): Observable<HttpEvent<any>> {
    if (
      event instanceof HttpResponse &&
      event.body &&
      typeof event.body.data === "string"
    ) {
      return this.decryptResponseBody(event);
    }
    return of(event);
  }

  /*
   * Desencripta el cuerpo de la respuesta usando decryptMessage
   * Devuelve la respuesta desencriptada.
   */
  private decryptResponseBody(
    event: HttpResponse<any>
  ): Observable<HttpEvent<any>> {
    return this.encryptionService.decryptMessage(event.body.data).pipe(
      switchMap((decryptedBody) => {
        // * Intentar parsear como JSON
        let responseBody;
        try {
          responseBody = JSON.parse(decryptedBody);
        } catch (e) {
          // * Si no es JSON, tratarlo como HTML
          responseBody = decryptedBody;
        }
        const clonedEvent = event.clone({ body: responseBody });
        //* Para poder observar el descifrado
        return of(clonedEvent);
      }),
      catchError((error) => {
       // console.error("Error decrypting response:", error);
        return of(event);
      })
    );
  }

  private handleRegularRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap((event: HttpEvent<any>) => this.handleRegularResponse(event)),
      catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
    );
  }

  private handleRegularResponse(
    event: HttpEvent<any>
  ): Observable<HttpEvent<any>> {
    if (
      event instanceof HttpResponse &&
      event.body &&
      typeof event.body.data === "string"
    ) {
      return this.decryptResponseBody(event);
    }
    return of(event);
  }

  private handleErrorResponse(error: HttpErrorResponse): Observable<never> {
    if (
      error.error &&
      error.error.data &&
      typeof error.error.data === "string"
    ) {
      return this.encryptionService.decryptMessage(error.error.data).pipe(
        switchMap((decryptedBody) => {
          const decryptedError = new HttpErrorResponse({
            ...error,
            error: { ...error.error, data: decryptedBody },
          });
          return throwError(() => decryptedError);
        }),
        catchError((decryptError) => {
          /* console.error(
            "Error decrypting error response:",
            decryptError.error.data
          ); */
          return throwError(() => decryptError);
        })
      );
    }
    return throwError(() => error);
  }
}
